import * as actionTypes from "../actionTypes";
import * as api from "../../api/index";
import { toast } from 'react-toastify';
import CheckIcon from '@mui/icons-material/Check';
import ErrorOutlineRoundedIcon from '@mui/icons-material/ErrorOutlineRounded';
import styles from "../../global.scss";


export const LogIn = (formData, navigate, setLoading) => async (dispatch) => {
  dispatch({ type: actionTypes.CLEAR_ERRORS });
  setLoading(true);
  try {
    const { data } = await api.login(formData);
    if (data.error === false) {
      dispatch({ type: actionTypes.LOG_IN, payload: data });
      toast(
        <div className="flexCenter_Row" style={{maxHeight: "24px"}}>
          <CheckIcon sx={{ color: styles["primary_color"], marginRight: "10px"}}/>
          Login Successful! Please wait while we prepare your Expense Management dashboard.
        </div>
        )
      navigate("/");
    }else{
      dispatch({ type: actionTypes.SET_ERRORS, payload: data.message });
      toast(
        <div className="flexCenter_Row" style={{maxHeight: "24px"}}>
          <ErrorOutlineRoundedIcon sx={{ color: styles["primary_color"], marginRight: "10px"}}/>
          {data.message}
        </div>
        )
    }
    setLoading(false);
  } catch (error) {
    dispatch({ type: actionTypes.SET_ERRORS, payload: error?.response?.data });
    setLoading(false);
  }
};

export const UserCheck = (formData, setLoading) => async (dispatch) => {
  dispatch({ type: actionTypes.CLEAR_ERRORS });
  setLoading(true);
  try {
    const { data } = await api.userExists(formData);
    // console.log(data)
    if (data.status === 'success') {
      if(data.data.UserType === "not exists"){
        // user doesnt exist
        toast(
          <div className="flexCenter_Row" style={{maxHeight: "24px"}}>
            <ErrorOutlineRoundedIcon sx={{ color: styles["primary_color"], marginRight: "10px"}}/>
            User does not exists
          </div>
          )
      }else{
        // call forgot pwd
        // eslint-disable-next-line no-unused-vars
        const { forgot } = await api.forgotPassword(formData);
        // console.log(forgot)
        toast(
          <div className="flexCenter_Row" style={{maxHeight: "24px"}}>
            <CheckIcon sx={{ color: styles["primary_color"], marginRight: "10px"}}/>
            We have sent you an email containing link to reset your password. Please check your spam folder too.
          </div>
          )
      }
    }else{
      toast(
        <div className="flexCenter_Row" style={{maxHeight: "24px"}}>
          <ErrorOutlineRoundedIcon sx={{ color: styles["primary_color"], marginRight: "10px"}}/>
          {data.message}
        </div>
        )
    }
    setLoading(false);
  } catch (error) {
    dispatch({ type: actionTypes.SET_ERRORS, payload: error?.response?.data });
    setLoading(false);
  }
};

export const GetUserAccess = (companyId, empId, page, limit) => async (dispatch) => {
  dispatch({ type: actionTypes.CLEAR_ERRORS });
  try {
    const { data } = await api.getUserAccess(companyId, empId, page, limit);
    if (data.status === "success") {
      dispatch({ type: actionTypes.GET_USER_ACCESS, payload: data.data });
    }
  } catch (error) {
    dispatch({ type: actionTypes.SET_ERRORS, payload: error?.response?.data });
  }
}