//ERRORS
export const SET_ERRORS = "SET_ERRORS";
export const CLEAR_ERRORS = "CLEAR_ERRORS";
//MESSAGE
export const SET_MESSAGE = "SET_MESSAGE";
export const CLEAR_MESSAGE = "CLEAR_MESSAGE";
//AUTH
export const LOG_IN = "LOG_IN";
export const TOKEN_FETCH = 'TOKEN_FETCH';
export const REFRESH_TOKEN = 'REFRESH_TOKEN';
export const LOG_OUT = "LOG_OUT";
export const GET_USER_ACCESS = 'GET_USER_ACCESS';


//SUBSCRIPTION
export const GET_ACTIVE_SUBS = 'GET_ACTIVE_SUBS';
export const GET_INCANCELLATION_SUBS = 'GET_INCANCELLATION_SUBS';
export const GET_BUNDLE = 'GET_BUNDLE';
export const GET_SUBS = 'GET_SUBS';
export const GET_PRODUCT_DETAIL = 'GET_PRODUCT_DETAIL';

//BRANCHES
export const GET_VIEW_DETAILS = 'GET_VIEW_DETAILS';
export const GET_BRANCHES = 'GET_BRANCHES';

//GRADE
export const GET_GRADES = 'GET_GRADES';

//COST CENTERS
export const GET_COST_CENTERS = 'GET_COST_CENTERS';

//GROUP TYPES
export const GET_GROUP_TYPES_ALL = 'GET_GROUP_TYPES_ALL';
export const GET_GROUP_TYPES_GROUP = 'GET_GROUP_TYPES_GROUP';

//GROUPS
export const GET_GROUPS = 'GET_GROUPS';
export const GET_GROUP_EMPLOYEES = 'GET_GROUP_EMPLOYEES';

//EMPLOYEES
export const GET_USER_ROLES = 'GET_USER_ROLES';
export const GET_EMPLOYEES = 'GET_EMPLOYEES';
export const GET_EMPLOYEE_DETAIL = 'GET_EMPLOYEE_DETAIL';
export const GET_EMPLOYEE_GROUPS = 'GET_EMPLOYEE_GROUPS';

export const GET_PRODUCT_LIST = 'GET_PRODUCT_LIST';
export const GET_COMPANY_ROLES = 'GET_COMPANY_ROLES';
export const GET_ALL_USER_ACCESS = 'GET_ALL_USER_ACCESS';

//MISCELLANEOUS
export const GET_MY_DETAILS = 'GET_MY_DETAILS';
export const GET_GROUP_TYPES = 'GET_GROUP_TYPES';
export const SEARCH_EMPLOYEE = 'SEARCH_EMPLOYEE';

export const GET_CITY_LIST = 'GET_CITY_LIST';
export const SEARCH_APPROVER = 'SEARCH_APPROVER';