import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation, Outlet } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { Divider } from "@mui/material";

import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

import Logo from "../../assets/icon/logo-getout-normal.png";
import MenuIcon from '@mui/icons-material/Menu';
import AppsIcon from '@mui/icons-material/Apps';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import DashboardIcon from '@mui/icons-material/Dashboard';
import LogoutIcon from '@mui/icons-material/Logout';
import PersonIcon from '@mui/icons-material/Person';
import LockIcon from '@mui/icons-material/Lock';
import SmsFailedIcon from '@mui/icons-material/SmsFailed';
import ContactSupportIcon from '@mui/icons-material/ContactSupport';
import ReceiptIcon from '@mui/icons-material/Receipt';
import BusinessIcon from '@mui/icons-material/Business';
import StarIcon from '@mui/icons-material/Star';
import PaidIcon from '@mui/icons-material/Paid';
import GroupWorkIcon from '@mui/icons-material/GroupWork';
import GroupIcon from '@mui/icons-material/Group';
import GroupAddIcon from '@mui/icons-material/GroupAdd';

import AppSwitcher from '../Utils/AppSwitcher';

import { 
  BranchesPage,
  BulkEmployeesPage,
  CostCentersPage,
  EmployeesPage,
  GradesPage,
  GroupsPage,
  GroupTypePage,
  SubscriptionPage
} from '../../routes/routes';

import styles from "../../global.scss";
import "./styles.scss";

// import Ripples from 'react-ripples';
// import ButtonBase from '@mui/material/ButtonBase';

const Navigation = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(0);
  const [collapsed, setCollapsed] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [menuAnchor, setMenuAnchor] = useState(false);
  const menuOpen = Boolean(menuAnchor);

  const user = useSelector(state => state.auth.user);

  useEffect(() => {
    var route = location.pathname.split("/")
    if(route.length >2){
      var temp = route[1] + "/" + route[2]
    }else{
      // eslint-disable-next-line no-redeclare
      var temp = route[1]
    }
    // console.log(temp)

    switch(temp){
      case "": return setCurrentPage(0);
      case "subscription": return setCurrentPage(1);
      case "branches": return setCurrentPage(2);
      case "grades": return setCurrentPage(3);
      case "cost-centers": return setCurrentPage(4);
      case "group-types": return setCurrentPage(5);
      case "groups": return setCurrentPage(6);
      case "employees": return setCurrentPage(7);
      case "bulk-employees": return setCurrentPage(8);

      default: 
        return setCurrentPage(-1);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, location.pathname]);

  const logoutHandler = () => {
    sessionStorage.clear();
    window.location.href = `${process.env.REACT_APP_LOGIN_URL}/logout/login?redirect_url=${process.env.REACT_APP_REDIRECT_URL}/verify/`;
    return null;
  };

  return (
    <div className='bgMain'>
      <div className='nav__topBar'>
        <div className='flexCenter_Row'>
          <div 
            style={{ borderRadius: "50%", width: "40px", height: "40px", overflow: 'hidden'}} 
              className='flexCenterCenterRow'>
            <IconButton onClick={() => setCollapsed(!collapsed)} aria-label="menu">
              <MenuIcon sx={{ color: styles["black_color"]}}/>
            </IconButton>
          </div>
           
          <img  
            src={Logo}
            alt=""
            style={{width: "91px", marginLeft: "10px"}}
            />

          <div className='nav__company'>
            {user?.data?.company_info?.LegalName}
          </div>
        </div>
        <div className='flexCenter_Row'>
          <div style={{ borderRadius: "50%", width: "40px", height: "40px", overflow: 'hidden'}} 
              className='flexCenterCenterRow'>
            <IconButton onClick={(e) => setMenuAnchor(e.currentTarget)} aria-label="app-switch">
              <AppsIcon sx={{ color: styles["primary_color"]}}/>
            </IconButton>
          </div>
          <h3>
            {user?.data?.employee_info?.DisplayName}
          </h3>
          <div 
            style={{ borderRadius: "50%", width: "40px", height: "40px", overflow: 'hidden'}} 
              className='flexCenterCenterRow'>
            <IconButton onClick={(e) => setAnchorEl(e.currentTarget)} aria-label="profile-menu">
              <ArrowDropDownIcon sx={{ color: styles["black_color"]}}/>
            </IconButton>
          </div>
          <Menu
            anchorEl={anchorEl}
            open={open}
            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            onClose={() => setAnchorEl(null)}
            disableScrollLock={true}
            sx={{
              '& .MuiPaper-root': {
                borderRadius: "16px",
              }}}
            MenuListProps={{
              'aria-labelledby': 'basic',
            }}
          >
          <a href={process.env.REACT_APP_BIZ_ACCOUNT} 
            style={{textDecoration: "none", color: styles['black_color']}}>
            <MenuItem style={{ fontSize: "14px", height: "48px" }}>
              <PersonIcon sx={{ color: styles["grey_icon"], marginRight: "16px"}}/>
              Profile
            </MenuItem>
          </a>  
          <a href={process.env.REACT_APP_BIZ_ACCOUNT} 
            style={{textDecoration: "none", color: styles['black_color']}}>
            <MenuItem style={{ fontSize: "14px", height: "48px" }}>
              <LockIcon sx={{ color: styles["grey_icon"], marginRight: "16px"}}/>
              Password-Reset
            </MenuItem>
          </a> 
          <a href={process.env.REACT_APP_BIZ_ACCOUNT} 
            style={{textDecoration: "none", color: styles['black_color']}}>
            <MenuItem style={{ fontSize: "14px", height: "48px" }}>
              <ContactSupportIcon sx={{ color: styles["grey_icon"], marginRight: "16px"}}/>
              Support
            </MenuItem>
          </a> 
          <a href={process.env.REACT_APP_BIZ_ACCOUNT} 
            style={{textDecoration: "none", color: styles['black_color']}}>
            <MenuItem style={{ fontSize: "14px", height: "48px" }}>
              <SmsFailedIcon sx={{ color: styles["grey_icon"], marginRight: "16px"}}/>
              App Feedback
            </MenuItem>
          </a> 
            <MenuItem onClick={logoutHandler} style={{ fontSize: "14px", height: "48px" }}>
              <LogoutIcon sx={{ color: styles["grey_icon"], marginRight: "16px"}}/>
              Sign Out
            </MenuItem>
          </Menu>

          <Menu
            anchorEl={menuAnchor}
            open={menuOpen}
            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            onClose={() => setMenuAnchor(null)}
            disableScrollLock={true}
            sx={{
              '& .MuiPaper-root': {
                borderRadius: "16px",
              }}}
            MenuListProps={{
              'aria-labelledby': 'basic',
            }}
          >
            {AppSwitcher()}
          </Menu>
        </div>
      </div>
      
      <div className='content'>
        <div className={`sideBar ${collapsed ? "sideBar__collapsed" : ""}`}>
          <>
                <div className={`${currentPage === 0 ? "sideBar__item-selected" :"sideBar__item"} flexCenter_Row`} 
                  onClick={() => {
                    setCurrentPage(0); 
                    navigate("/");
                  }}>
                  <DashboardIcon sx={{ color: styles[currentPage === 0 ? "white_color": "new_color"], transition: "margin 0.2s",
                      marginRight: !collapsed && "10px", marginLeft: collapsed ? "10px"  : "15px" }}/>
                  {!collapsed && "General Admin"}
                </div>
              {
                (localStorage.getItem("permissions")?.includes("GEN_SUB_V") ||
                localStorage.getItem("permissions")?.includes("GEN_BRAN_V") ||
                localStorage.getItem("permissions")?.includes("GEN_GRAD_V") ||
                localStorage.getItem("permissions")?.includes("GEN_COST_V") ||
                localStorage.getItem("permissions")?.includes("GEN_GROUP_V") ||
                localStorage.getItem("permissions")?.includes("GEN_EMP_V") ||
                localStorage.getItem("permissions")?.includes("GEN_BULKEMP_V"))
                &&
                !collapsed 
                ? 
                  <Divider textAlign="left" sx={{ marginTop: "16px", marginBottom: "16px",  marginLeft: "10px"}}>
                    <h4 style={{fontSize: 15}}>COMPANY SETTINGS</h4>
                  </Divider> 
                :
                  <Divider sx={{ marginTop: "24px", marginBottom: "24px"}}/> 
              }
                              
              {
                localStorage.getItem("permissions")?.includes("GEN_SUB_V")
                &&
                <div className={`${currentPage === 1 ? "sideBar__item-selected" :"sideBar__item"} flexCenter_Row`} 
                  onClick={() => {
                    setCurrentPage(1); 
                    navigate("/subscription");
                  }}
                  onMouseEnter={() => SubscriptionPage.preload()}>
                  <ReceiptIcon sx={{ color: styles[currentPage === 1 ? "white_color": "new_color"], transition: "margin 0.2s",
                      marginRight: !collapsed && "10px", marginLeft: collapsed ? "10px"  : "15px" }}/>
                  {!collapsed && "Subscription"}
                </div>
              }
              {
                localStorage.getItem("permissions")?.includes("GEN_BRAN_V")
                &&
                <div className={`${currentPage === 2 ? "sideBar__item-selected" :"sideBar__item"} flexCenter_Row`} 
                  onClick={() => {
                    setCurrentPage(2); 
                    navigate("/branches");
                  }}
                  onMouseEnter={() => BranchesPage.preload()}>
                  <BusinessIcon sx={{ color: styles[currentPage === 2 ? "white_color": "new_color"], transition: "margin 0.2s",
                      marginRight: !collapsed && "10px", marginLeft: collapsed ? "10px"  : "15px" }}/>
                  {!collapsed && "Branches"}
                </div>
              }
              {
                localStorage.getItem("permissions")?.includes("GEN_GRAD_V")
                &&
                <div className={`${currentPage === 3 ? "sideBar__item-selected" :"sideBar__item"} flexCenter_Row`} 
                  onClick={() => {
                    setCurrentPage(3); 
                    navigate("/grades");
                  }}
                  onMouseEnter={() => GradesPage.preload()}>
                  <StarIcon sx={{ color: styles[currentPage === 3 ? "white_color": "new_color"], transition: "margin 0.2s",
                      marginRight: !collapsed && "10px", marginLeft: collapsed ? "10px"  : "15px" }}/>
                  {!collapsed && "Grades"}
                </div>
              }
              {
                localStorage.getItem("permissions")?.includes("GEN_COST_V")
                &&
                <div className={`${currentPage === 4 ? "sideBar__item-selected" :"sideBar__item"} flexCenter_Row`} 
                  onClick={() => {
                    setCurrentPage(4); 
                    navigate("/cost-centers");
                  }}
                  onMouseEnter={() => CostCentersPage.preload()}>
                  <PaidIcon sx={{ color: styles[currentPage === 4 ? "white_color": "new_color"], transition: "margin 0.2s",
                      marginRight: !collapsed && "10px", marginLeft: collapsed ? "10px"  : "15px" }}/>
                  {!collapsed && "Cost Centers"}
                </div>
              }
              {
                localStorage.getItem("permissions")?.includes("GEN_GROUP_V")
                &&
                <div className={`${currentPage === 5 ? "sideBar__item-selected" :"sideBar__item"} flexCenter_Row`} 
                  onClick={() => {
                    setCurrentPage(5); 
                    navigate("/group-types");
                  }}
                  onMouseEnter={() => GroupTypePage.preload()}>
                  <PaidIcon sx={{ color: styles[currentPage === 5 ? "white_color": "new_color"], transition: "margin 0.2s",
                      marginRight: !collapsed && "10px", marginLeft: collapsed ? "10px"  : "15px" }}/>
                  {!collapsed && "Group Types"}
                </div>
              }
              {
                localStorage.getItem("permissions")?.includes("GEN_GROUP_V")
                &&
                <div className={`${currentPage === 6 ? "sideBar__item-selected" :"sideBar__item"} flexCenter_Row`} 
                  onClick={() => {
                    setCurrentPage(6); 
                    navigate("/groups");
                  }}
                  onMouseEnter={() => GroupsPage.preload()}>
                  <GroupWorkIcon sx={{ color: styles[currentPage === 6 ? "white_color": "new_color"], transition: "margin 0.2s",
                      marginRight: !collapsed && "10px", marginLeft: collapsed ? "10px"  : "15px" }}/>
                  {!collapsed && "Groups"}
                </div>
              }
              {
                localStorage.getItem("permissions")?.includes("GEN_EMP_V")
                &&
                <div className={`${currentPage === 7 ? "sideBar__item-selected" :"sideBar__item"} flexCenter_Row`} 
                  onClick={() => {
                    setCurrentPage(7); 
                    navigate("/employees");
                  }}
                  onMouseEnter={() => EmployeesPage.preload()}>
                  <GroupIcon sx={{ color: styles[currentPage === 7 ? "white_color": "new_color"], transition: "margin 0.2s",
                      marginRight: !collapsed && "10px", marginLeft: collapsed ? "10px"  : "15px" }}/>
                  {!collapsed && "Employees"}
                </div>
              }
              {
                localStorage.getItem("permissions")?.includes("GEN_BULKEMP_V")
                &&
                <div className={`${currentPage === 8 ? "sideBar__item-selected" :"sideBar__item"} flexCenter_Row`} 
                  onClick={() => {
                    setCurrentPage(8); 
                    navigate("/bulk-employees");
                  }}
                  onMouseEnter={() => BulkEmployeesPage.preload()}>
                  <GroupAddIcon sx={{ color: styles[currentPage === 8 ? "white_color": "new_color"], transition: "margin 0.2s",
                      marginRight: !collapsed && "10px", marginLeft: collapsed ? "10px"  : "15px" }}/>
                  {!collapsed && "Bulk Employees"}
                </div>
              }
          </>
        </div>
        <div className='rightPanel' style={{ marginTop: "84px", transition: "padding 0.2s",
          width: "100%", minHeight: "100vh", height: "100%", paddingBottom: "10px"}}>
          {<Outlet />}
        </div>
      </div>
    </div>
  )
}

export default Navigation;