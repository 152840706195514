import React from 'react';

import { Divider } from "@mui/material";

import CardIcon from "../../assets/icon/card.svg"; 
import ExpenseIcon from "../../assets/icon/expense.svg";
import BookIcon from "../../assets/icon/book.svg";
import ExperiencesIcon from "../../assets/icon/experiences.svg";
import PolicyIcon from "../../assets/icon/policy.svg";
import AdminIcon from "../../assets/icon/admin.svg";
import CardAdminIcon from "../../assets/icon/admin-card.svg";
import ExpenseAdminIcon from "../../assets/icon/admin-expense.svg";
import TravelAdminIcon from "../../assets/icon/admin-travel.svg";

import styles from "../../global.scss";

const AppSwitcher = () => {
  return (
    <div style={{ fontSize: "13px", padding: "10px 15px 10px 15px"}}>
      {
        (localStorage.getItem("permissions")?.includes("CRD_APP") ||
        localStorage.getItem("permissions")?.includes("EXP_APP"))
        &&
        <>
        <h5 style={{fontSize: 17, margin: "10px"}}> 
          BizSpend
        </h5> 
        <Divider />
        </>
      }
      <div className='grid_3items'>
        {
          localStorage.getItem("permissions")?.includes("CRD_APP")
          &&
          <a href={process.env.REACT_APP_CARD}
            style={{textDecoration: "none", color: styles['black_color']}}>
            <div className="flexCenterCenterColumn menuItem" onClick={() => {localStorage.clear(); sessionStorage.clear()}}> 
              <img src={CardIcon} alt="" className='customIcon'/>
              Card
            </div>
          </a>
        }
        {
          localStorage.getItem("permissions")?.includes("EXP_APP")
          &&
          <a href={process.env.REACT_APP_EXPENSE}
            style={{textDecoration: "none", color: styles['black_color']}}>
            <div className="flexCenterCenterColumn menuItem" onClick={() => {localStorage.clear(); sessionStorage.clear()}}> 
              <img src={ExpenseIcon} alt="" className='customIcon'/>
              Expense
            </div>
          </a>
        }
      </div>
      <h5 style={{fontSize: 17, margin: "10px"}}> 
        BizTravel
      </h5> 
      <Divider />
      <div className='grid_3items'>
        {
          localStorage.getItem("permissions")?.includes("TRV_APP")
          &&
          <a href={process.env.REACT_APP_TRAVEL_POLICY} 
            style={{textDecoration: "none", color: styles['black_color']}}>
            <div className="flexCenterCenterColumn menuItem" onClick={() => {localStorage.clear(); sessionStorage.clear()}}> 
              <img src={PolicyIcon} alt="" className='customIcon'/>
              Policy
            </div>
          </a>
        }
        {/* {
          localStorage.getItem("permissions")?.includes("TRV_ADMIN_V")
          && */}
          <a href={"https://getout.travel"}
            target="_blank" rel="noopener noreferrer" style={{textDecoration: "none", color: styles['black_color']}}>
            <div className="flexCenterCenterColumn menuItem"> 
              <img src={BookIcon} alt="" className='customIcon'/>
              Book
            </div>
          </a>
        {/* }
        {
          localStorage.getItem("permissions")?.includes("TRV_ADMIN_V")
          && */}
          <a href={"https://getout.travel/explore-destination"}
            target="_blank" rel="noopener noreferrer" style={{textDecoration: "none", color: styles['black_color']}}>
            <div className="flexCenterCenterColumn menuItem"> 
              <img src={ExperiencesIcon} alt="" className='customIcon'/>
              Experiences
            </div>
          </a>
        {/* } */}
      </div>
      {
        (localStorage.getItem("permissions")?.includes("GEN_ADMIN_APP") ||
        localStorage.getItem("permissions")?.includes("CRD_ADMIN_APP") ||
        localStorage.getItem("permissions")?.includes("EXP_ADMIN_APP") ||
        localStorage.getItem("permissions")?.includes("TRV_ADMIN_APP"))
        &&
        <>
          <h5 style={{fontSize: 17, margin: "10px"}}> 
            Admin
          </h5> 
          <Divider />
        </>
      }
      <div className='grid_3items'>
        {
          localStorage.getItem("permissions")?.includes("GEN_ADMIN_APP")
          &&
          <>
          {/* <a href={process.env.REACT_APP_COMMON_ADMIN} 
            style={{textDecoration: "none", color: styles['black_color']}}> */}
            <div className="flexCenterCenterColumn menuItem__disabled"> 
              <img src={AdminIcon} alt="" className='customIcon'/>
              General
            </div>
          {/* </a> */}
          </>
        }
        {
          localStorage.getItem("permissions")?.includes("CRD_ADMIN_APP")
          &&
          <a href={process.env.REACT_APP_CARD_ADMIN} 
            style={{textDecoration: "none", color: styles['black_color']}}>
            <div className="flexCenterCenterColumn menuItem" onClick={() => {localStorage.clear(); sessionStorage.clear()}}> 
              <img src={CardAdminIcon} alt="" className='customIcon'/>
              Card
            </div>
          </a>
        }
      
        {
          localStorage.getItem("permissions")?.includes("EXP_ADMIN_APP")
          &&
          <a href={process.env.REACT_APP_EXPENSE_ADMIN} 
            style={{textDecoration: "none", color: styles['black_color']}}>
            <div className="flexCenterCenterColumn menuItem"> 
              <img src={ExpenseAdminIcon} alt="" className='customIcon'/>
                Expense
            </div>
          </a>
        }
        
        {
          localStorage.getItem("permissions")?.includes("TRV_ADMIN_APP")
          &&
          <a href={process.env.REACT_APP_TRAVEL_ADMIN} 
            style={{textDecoration: "none", color: styles['black_color']}}>
            <div className="flexCenterCenterColumn menuItem" onClick={() => {localStorage.clear(); sessionStorage.clear()}}> 
              <img src={TravelAdminIcon} alt="" className='customIcon'/>
              Travel
            </div>
          </a>
        }
      </div>
    </div>
  )
}

export default AppSwitcher;