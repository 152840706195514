import * as actionTypes from "../actionTypes.js";

const initialState = {
  grades: null
};

const gradesReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_GRADES:
      return { ...state, grades: action.payload };

    default:
      return state;
  }
};

export default gradesReducer;
