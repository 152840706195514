import * as actionTypes from "../actionTypes.js";

const initialState = {
  employees: null,
  employeeDetail: null,
  employeeGroups: null,
  userRoles: null,
  productList: null,
  companyRoles: null,
  allUserAccess: null
};

const employeesReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_EMPLOYEES:
      return { ...state, employees: action.payload };
    case actionTypes.GET_EMPLOYEE_DETAIL:
      return { ...state, employeeDetail: action.payload };
    case actionTypes.GET_USER_ROLES:
      return { ...state, userRoles: action.payload };

    // ------ roles && permission ------
    case actionTypes.GET_PRODUCT_LIST:
      return { ...state, productList: action.payload };
    case actionTypes.GET_COMPANY_ROLES:
      return { ...state, companyRoles: action.payload };
    case actionTypes.GET_ALL_USER_ACCESS:
      return { ...state, allUserAccess: action.payload };

    default:
      return state;
  }
};

export default employeesReducer;
