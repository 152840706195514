import React, { useEffect, useState } from "react";
import { Outlet, useLocation } from "react-router-dom";
// import Navigation from "../components/Navigation/Navigation";

const useAuth = () => {
  const user = sessionStorage.getItem("user");
  if (user) {
    return true;
  } else {
    return false;
  }
};

const ProtectedRoutes = (props) => {
  const [auth, setAuth] = useState(useAuth());
  const location = useLocation()
  const user = sessionStorage.getItem("user");
  
  useEffect(() => {
    user ? setAuth(true) : setAuth(false) 
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[location]);

  return auth ? (
    <>
      {<Outlet />}
    </>
  ) : (
    <div style={{ width: "100%", height: "100vh", backgroundColor: "white", visibility: "hidden"}}>
      {window.location.href = `${process.env.REACT_APP_LOGIN_URL}/auth/login?redirect_url=${process.env.REACT_APP_REDIRECT_URL}/verify/`}
    </div>
  );
};

export default ProtectedRoutes;
