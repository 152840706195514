import React, { Suspense, useEffect, useState } from "react";
import { lazyWithPreload } from "react-lazy-with-preload";
import { BrowserRouter as Router, Routes, Route, useNavigate, useLocation } from "react-router-dom";
import { useIdleTimer } from 'react-idle-timer';
import { useDispatch } from "react-redux";
import CryptoJS from "crypto-js";

import ProtectedRoutes from "./protectedRoutes";
import PublicRoutes from "./publicRoutes";

import Footer from "../components/Footer/footer";
import Navigation from "../components/Navigation/Navigation";
import NoNotifIcon from "../assets/images/empty-tray.png";
import logo from "../assets/icon/logo-getout-normal.png";

import { GetUserAccess } from "../redux/actions/authActions";

import TimeoutModal from "../components/Modals/TimeoutModal";

import * as actionTypes from "../redux/actionTypes";

import "../views/homePage/styles.scss";

//routes
// import SubscriptionPage from "../views/subscriptionPage/SubscriptionPage";
// import BranchesPage from "../views/branchesPage/BranchesPage";
// import GradesPage from "../views/gradesPage/GradesPage";
// import CostCentersPage from "../views/costCentersPage/CostCentersPage";
// import GroupTypePage from "../views/groupTypesPage/GroupTypePage";
// import GroupsPage from "../views/groupsPage/GroupsPage";
// import EmployeesPage from "../views/employeesPage/EmployeesPage";
// import BulkEmployeesPage from "../views/bulkEmployeesPage/BulkEmployeesPage";

export const SubscriptionPage = lazyWithPreload(() => import("../views/subscriptionPage/SubscriptionPage"));
export const BranchesPage = lazyWithPreload(() => import("../views/branchesPage/BranchesPage"));
export const GradesPage = lazyWithPreload(() => import("../views/gradesPage/GradesPage"));
export const CostCentersPage = lazyWithPreload(() => import("../views/costCentersPage/CostCentersPage"));
export const GroupTypePage = lazyWithPreload(() => import("../views/groupTypesPage/GroupTypePage"));
export const GroupsPage = lazyWithPreload(() => import("../views/groupsPage/GroupsPage"));
export const EmployeesPage = lazyWithPreload(() => import("../views/employeesPage/EmployeesPage"));
export const BulkEmployeesPage = lazyWithPreload(() => import("../views/bulkEmployeesPage/BulkEmployeesPage"));

const MainRoutes = () => {
  const [timeOutOpen, setTimeOutOpen] = useState(false);

  const timeout = 15 * 60 * 1000;

  const handleOnActive = () => {}
  const handleOnIdle = () => {
    console.log("time out")
    setTimeOutOpen(!timeOutOpen)
  }

  // eslint-disable-next-line no-unused-vars
  const { getRemainingTime } = useIdleTimer({timeout, onActive: handleOnActive, onIdle: handleOnIdle })

  const handleLogOff = () => setTimeOutOpen(false);

  const handleKeepActive = () => setTimeOutOpen(false);

  return(
    <Router>
      <TimeoutModal open={timeOutOpen} setOpen={setTimeOutOpen} button1={handleLogOff} button2={handleKeepActive}/>
      <Suspense fallback={<LoadingScreen />}>
        <Routes>
          {/* Protected Routes */}
          <Route path="/" element={<ProtectedRoutes />}>
            <Route path="/auth" element={<External />} />

            <Route path="/" element={<Navigation />} >
              <Route path="" exact element={<WelcomeMsg />} />
              <Route path="subscription" exact element={<SubscriptionPage />} />
              <Route path="branches" exact element={<BranchesPage />} />
              <Route path="grades" exact element={<GradesPage />} />
              <Route path="cost-centers" exact element={<CostCentersPage />} />
              <Route path="group-types" exact element={<GroupTypePage />} />
              <Route path="groups" exact element={<GroupsPage />} />
              <Route path="employees" exact element={<EmployeesPage />} />
              <Route path="bulk-employees" exact element={<BulkEmployeesPage />} />
            </Route>
          </Route>

          {/* Public Routes */}
          <Route path="/" element={<PublicRoutes />}>
            <Route path="/auth" element={<External />} />
            <Route path="/verify/:id" exact element={<VerifyToken />} />
          </Route>
        </Routes>
      <Footer />
      </Suspense>
    </Router>
)};

const LoadingScreen = () => {
  return (
    <div style={{ width: "100%", height: "100vh" }} className='flexCenterCenterColumn'>
      <div className="lds-ring">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
      </div>
      <img
        width={120}
        style={{position: "absolute", left: "calc(50% - 60px)", bottom: "22vh", }}
        src={logo}
        alt="" />
    </div>
  )
}

const VerifyToken = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    sessionStorage.clear();
    localStorage.clear();

    // console.log(location.search);
      var token = location.search.split("&access=");
      var companyToken = token[0].split("&company=");
      var userToken = companyToken[0].split("user=");

      // user
      var bytes = CryptoJS.AES.decrypt(decodeURIComponent(userToken[1]), process.env.REACT_APP_SECRET_KEY);
      var decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
      // console.log(decryptedData)

      // company
      var bytes2 = CryptoJS.AES.decrypt(decodeURIComponent(companyToken[1]), process.env.REACT_APP_SECRET_KEY);
      var decryptedData2 = JSON.parse(bytes2.toString(CryptoJS.enc.Utf8));
      // console.log(decryptedData2)

      var employeeInfo = decryptedData;
      var companyInfo = decryptedData2;

      var obj = {
        access_token: { token: token[1] },
        employee_info: employeeInfo,
        company_info: companyInfo
      }
    dispatch({ type: actionTypes.LOG_IN, payload: { data: obj }});
    dispatch(GetUserAccess(employeeInfo.CompanyId, employeeInfo.EmployeeId, 1, 1000)).then(() => {
      navigate("/");
    })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  
  return (
    <div style={{ width: "100%", height: "100vh", backgroundColor: "white"}}></div>
  )
}

const External = () => {
  sessionStorage.clear();
  localStorage.clear();
  window.location.href = `${process.env.REACT_APP_LOGIN_URL}/auth/login?redirect_url=${process.env.REACT_APP_REDIRECT_URL}/verify/`;
  return null;
}

const WelcomeMsg = () => {
    return(
      <>
      <div className="home__content" style={{padding: "20px", fontWeight: "500", fontSize: "19px"}}>
        Welcome to General Admin Section !
      </div>
      <div className="home__content flexCenterCenterColumn" style={{padding: "20px", fontWeight: "500", fontSize: "15px"}}>
        <img
          src={NoNotifIcon}
          alt=""
            style={{ width: "60px", height: "50px", margin:"10px"}}
          />
        No new notification available
      </div>
      </>
    )
  }
  
export default MainRoutes;