import * as actionTypes from "../actionTypes.js";

const initialState = {
  groupTypesAll: null,
  groupTypesGroups: null
};

const groupTypesReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_GROUP_TYPES_ALL:
      return { ...state, groupTypesAll: action.payload };
    case actionTypes.GET_GROUP_TYPES_GROUP:
      return { ...state, groupTypesGroups: action.payload };

    default:
      return state;
  }
};

export default groupTypesReducer;
