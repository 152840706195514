import React, { useState, useEffect, useRef } from "react";
import { createTheme, ThemeProvider } from '@mui/material/styles';

import {
  Dialog,
  DialogContent,
  DialogContentText,
  Button,
} from "@mui/material";

import styles from "../../global.scss";

const theme = createTheme({
  palette: {
    primary: {
      main: styles["primary_color"]
    }
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          width: "250px",
          textTransform: 'none'
        }
      }
    }
  }
});

const TimeoutModal = ({ open, setOpen, button1, button2 }) => {
  const scroll = "paper";
  const descriptionElementRef = React.useRef(null);
  // eslint-disable-next-line no-unused-vars
  const [timerStart, setTimerStart] = useState(false);
  const [timer, setTimer] = useState('00:00:00');
  const Ref = useRef(null);

  const [id, setId] = useState(null); 

  useEffect(() => {
    if(open === true) {
      clearTimer(getDeadTime());
    }else{
      setId(null);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[open]);

  const handleClose = () => {
    setTimerStart(false);
    clearInterval(id);
    setId(null);
    setOpen(false);
  }

  // ------------------------- timer logic ---------------------
  const startTimer = (e) => {
    let { total, hours, minutes, seconds } = getTimeRemaining(e);
    if (total >= 0) {
      setTimer(
        (hours > 9 ? hours : '0' + hours) + ':' +
        (minutes > 9 ? minutes : '0' + minutes) + ':'
        + (seconds > 9 ? seconds : '0' + seconds)
      )
    }
  }

  const getTimeRemaining = (e) => {
    const total = Date.parse(e) - Date.parse(new Date());
    const seconds = Math.floor((total / 1000) % 60);
    const minutes = Math.floor((total / 1000 / 60) % 60);
    const hours = Math.floor((total / 1000 / 60 / 60) % 24);
    return { total, hours, minutes, seconds };
  }

  const clearTimer = (e) => {
    setTimer('00:05:00');
    if (Ref.current) clearInterval(Ref.current); 
    setId(setInterval(() => {
        startTimer(e);
        // console.log(getTimeRemaining(e))
        if(getTimeRemaining(e).total < 0) {
          handleLogout();
          handleClose();
        }
    }, 1000))
    Ref.current = id;
  }

  const getDeadTime = () => {
    let deadline = new Date();
    deadline.setMinutes(deadline.getMinutes() + 5);
    return deadline;
  }

  // --------------------- button 1 -------------------
  const handleLogout = () => {
    button1();
    window.location.href = `${process.env.REACT_APP_LOGIN_URL}/logout/login?redirect_url=${process.env.REACT_APP_REDIRECT_URL}/verify/`;
    return null;
  }

  return (
    <ThemeProvider theme={theme}>
      <Dialog
        fullWidth={true}
        maxWidth="sm"
        open={open}
        scroll={scroll}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        PaperProps={{
          style: { borderRadius: 20 }}}
      >
        <DialogContent>
          <DialogContentText sx={{ whiteSpace: "pre-wrap" }} id="scroll-dialog-description" ref={descriptionElementRef} tabIndex={-1}>
            <div style={{color: "black", fontSize: 16, fontWeight: 500, textAlign: "justify"}}>
              We have detected you have been inactive for 15 minutes on this page. 
              For security purposes, if you don't perform any operation
              you will be logged off and redirected to this home page.

              <div style={{marginTop: "20px"}}>
                {timer}
              </div>

              <div className="flexCenterSBRow" style={{ margin: "auto", width: "100%", gap:"50px", marginTop: "40px" }}>
                <Button variant="contained" sx={{ borderRadius: "24px"}} onClick={handleLogout}>Yes, please log off</Button>
                <Button variant="contained" sx={{ borderRadius: "24px"}} onClick={handleClose}>No Keep my session active</Button>
              </div>
            </div>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </ThemeProvider>
  );
};

export default TimeoutModal;
