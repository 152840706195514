import * as actionTypes from "../actionTypes.js";

const initialState = {
  branches: null,
  viewDetails: null
};

const branchesReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_BRANCHES:
      return { ...state, branches: action.payload };
    case actionTypes.GET_VIEW_DETAILS:
      return { ...state, viewDetails: action.payload };

    default:
      return state;
  }
};

export default branchesReducer;
