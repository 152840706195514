import * as actionTypes from "../actionTypes.js";

const initialState = {
  activeSubscription: null,
  incancellationSubscription: null,
  bundle: null,
  subscription: null,
  myDetails: null,
  productDetail: null
};

const subscriptionReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_ACTIVE_SUBS:
      return { ...state, activeSubscription: action.payload };
    case actionTypes.GET_INCANCELLATION_SUBS:
      return { ...state, incancellationSubscription: action.payload };
    case actionTypes.GET_BUNDLE:
      return { ...state, bundle: action.payload };
    case actionTypes.GET_SUBS:
      return { ...state, subscription: action.payload };
    case actionTypes.GET_MY_DETAILS:
      return { ...state, myDetails: action.payload };
    case actionTypes.GET_PRODUCT_DETAIL:
      return { ...state, productDetail: action.payload };
  

    default:
      return state;
  }
};

export default subscriptionReducer;
