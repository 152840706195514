import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from 'redux-persist/lib/storage';

import AuthReducers from "./authReducers";
import GradesReducer from "./gradesReducers";
import CostCentersReducer from "./costCentersReducers";
import GroupTypesReducer from "./groupTypesReducers";
import GroupsReducer from "./groupsReducers";
import BranchesReducer from "./branchesReducers";
import EmployeesReducer from "./employeesReducers";
import SubscriptionReducer from "./subscriptionReducers";
import MiscellaneousReducer from "./miscellaneousReducers";

const persistConfig = {
  key: "root",
  storage,
};

const rootReducer = combineReducers({
  auth: AuthReducers,
  grade: GradesReducer,
  costCenter: CostCentersReducer,
  groupType: GroupTypesReducer,
  group: GroupsReducer,
  branch: BranchesReducer,
  employee: EmployeesReducer,
  subscription: SubscriptionReducer,
  other: MiscellaneousReducer
});

export default persistReducer(persistConfig, rootReducer);