import * as actionTypes from "../actionTypes.js";

const initialState = {
  isAuthenticated: false,
  user: null,
  permissions: null,
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.LOG_IN:
      sessionStorage.setItem("user", JSON.stringify(action.payload));
      return { ...state, user: action.payload, isAuthenticated: true };
    case actionTypes.LOG_OUT:
      sessionStorage.clear();
      return initialState;
    case actionTypes.GET_USER_ACCESS:
      var temp = [];
      temp = [...temp, ...action.payload.Records.map((i) => i.Permission)];
      var list = [];
      temp.forEach((i) => {
        list = [...list, ...i]
      });
      var list1 = [];
      list.forEach((i => {
        list1 = [ ...list1, i.Code]
      }))
      console.log(list1);
      localStorage.setItem("permissions", list1);
      return { ...state, permissions: action.payload };
    default:
      return state;
  }
};

export default authReducer;
