import * as actionTypes from "../actionTypes.js";

const initialState = {
  groups: null,
  groupEmployees: null
};

const groupsReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_GROUPS:
      return { ...state, groups: action.payload };
    case actionTypes.GET_GROUP_EMPLOYEES:
      return { ...state, groupEmployees: action.payload };

    default:
      return state;
  }
};

export default groupsReducer;
