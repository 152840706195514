import React from 'react';

import styles from "../../global.scss";
import "./styles.scss";

const Footer = () => {
  return (
    <div className='footer'>
      <div className='footer__content'>
        <div style={{color: "white"}} className='footer__col1'>
          <h4 className='h4'>Support</h4>
          <a href="" target="_blank" rel="noopener noreferrer">
            <h5 className='h5'>Contact us</h5>
          </a>
        </div>
        <div style={{color: "white"}} className='footer__col2'>
          <h4 className='h4'>Quick links</h4>
          <a href="" target="_blank" rel="noopener noreferrer">
            <h5 className='h5'>Travel Booking</h5>
          </a>
          <a href="" target="_blank" rel="noopener noreferrer">
            <h5 className='h5'>Check your booking</h5>
          </a>
          <a href="" target="_blank" rel="noopener noreferrer">
            <h5 className='h5'>About us</h5>
          </a>
          <a href="" target="_blank" rel="noopener noreferrer">
            <h5 className='h5'>Privacy policy</h5>
          </a>
          <a href="" target="_blank" rel="noopener noreferrer">
            <h5 className='h5'>Careers 
              <span style={{ backgroundColor: styles['primary_color'], color: "white", fontSize: "12px", padding: "5px", marginLeft: "10px" }}>
                We're Hiring
              </span>
            </h5>
          </a>
          <a href="" target="_blank" rel="noopener noreferrer">
            <h5 className='h5'>Terms and conditions</h5>
          </a>
        </div>
        <div style={{color: "white"}} className='footer__col3'>
          <h4 className='h4'>Are you a business?</h4>
          <a href="" target="_blank" rel="noopener noreferrer">
            <h5 className='h5'>Hotels</h5>
          </a>
          <a href="" target="_blank" rel="noopener noreferrer">
            <h5 className='h5'>Corporates</h5>
          </a>
          <a href="" target="_blank" rel="noopener noreferrer">
            <h5 className='h5'>Become an affiliate</h5>
          </a>
        </div>
        <div style={{color: "white" }} className='footer__col4'>
          <h4 className='h4'>Payment & Security</h4>
          <div  className="textwidget">
            <ul  className="list-vrt">
              <li>
                <img alt="" src={"https://getout.travel/wp-content/themes/wp-bootstrap-4/assets/images/card/visa.jpg"} width={"70px"} />
              </li>
              <li >
                <img alt="" src={"https://getout.travel/wp-content/themes/wp-bootstrap-4/assets/images/card/master-card.jpg"} width={"70px"} />
              </li>
              <li >
                <img alt="" src={"https://getout.travel/wp-content/themes/wp-bootstrap-4/assets/images/card/paypal.jpg"} width={"70px"} />
              </li>
              <li >
                <img alt="" src={"https://getout.travel/wp-content/themes/wp-bootstrap-4/assets/images/card/american-ecpress.jpg"} width={"70px"} />
              </li>
              <li >
                <img alt="" src={"https://getout.travel/wp-content/themes/wp-bootstrap-4/assets/images/card/rupay.jpg"} width={"70px"} />
              </li>
              <li >
                <img alt="" src={"https://getout.travel/wp-content/themes/wp-bootstrap-4/assets/images/card/iata.jpg"} width={"70px"} />
              </li>
              <li >
                <img alt="" id="trustwaveSealImage" src={"https://sealserver.trustwave.com/seal_image.php?customerId=03cf4f21849844f3824d2a47dc513ee6&amp;size=105x54&amp;style=invert"} style={{cursor: "pointer"}} />
              </li>
            </ul>
            <ul  className="list-vrt">
              <li >
                <a  href="" target="_blank" rel="noopener noreferrer">
                  <img  alt="twitter" width="18"  src={"https://getout.travel/wp-content/themes/wp-bootstrap-4/assets/images/icon/twitter_alt.svg"}/>
                </a>
              </li>
              <li >
                <a  href="" target="_blank" rel="noopener noreferrer">
                  <img  alt="facebook" width="18"  src={"https://getout.travel/wp-content/themes/wp-bootstrap-4/assets/images/icon/facebook.svg"}/>
                </a>
              </li>
              <li >
                <a  href="" target="_blank" rel="noopener noreferrer">
                  <img  alt="insta" width="12" src={"https://getout.travel/wp-content/themes/wp-bootstrap-4/assets/images/icon/insta.svg"} />
                </a>
              </li>
              <li >
                <a  href="" target="_blank" rel="noopener noreferrer">
                  <img  alt="pinterest" width="10"  src={"https://getout.travel/wp-content/themes/wp-bootstrap-4/assets/images/icon/pinterest.svg"}/>
                </a>
              </li>
              <li >
                <a  href="" target="_blank" rel="noopener noreferrer">
                  <img  alt="linkedin" width="12"  src={"https://getout.travel/wp-content/themes/wp-bootstrap-4/assets/images/icon/linkedin.svg"} />
                </a>
              </li>
            </ul>
          </div>
        </div>          
      </div>
      <div style={{fontSize: "13px", fontWeight: "300"}} className='footer__col5'>
        Ⓒ 2023 Lusid. All Rights Reserved. (v{process.env.REACT_APP_VERSION})
      </div>
    </div>
  )
}

export default Footer;