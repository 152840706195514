import axios from "axios";
import { toast } from "react-toastify";

const API = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_URL}/`,
});

// Request Interceptor
API.interceptors.request.use((req) => {
  if (sessionStorage.getItem("user")) {
    req.headers.Authorization = `Bearer ${JSON.parse(sessionStorage.getItem("user"))?.data?.access_token?.token}`;
  }
  return req;
});

// Response Interceptor
API.interceptors.response.use(
  (response) => {
    // console.log(response);
    return response;
  },
  (error) => {
    // 5XX error 
    if((error.status+"")[0] === "5") toast.error("Something went wrong! Please try again later.")

    return Promise.reject(error);
  }
);

export const login = (form) => API.post("auth/v2/auth/login", form);
export const userExists = (form) => API.post('auth/user-exists', form);
export const forgotPassword = (form) => API.post('auth/forget-password', form); 
export const refreshToken = (form) => API.post('auth/v2/auth/get-access-token', form);
export const getUserAccess = (companyId, empId, page, limit) => 
  API.get(`auth/v2/mgmnt/permissions/user-access/getAll?page=${page}&limit=${limit}&CompanyId=${companyId}&EmployeeId=${empId}`);


// subscription
export const getActiveSubscription = () => API.get('auth/v2/subscription/active/');
export const getIncancellationSubscription = () => API.get('auth/v2/subscription/incancellation/');
export const getBundle = () => API.get('auth/v2/bundle/');
export const getSubscription = () => API.get('auth/v2/subscription/');
export const getMyDetails = () => API.get(`management/v1/employee/my-details/v1`);
export const getProductDetails = (id) => API.get(`auth/v2/plans/?ProductBundleId=${id}`);

export const createInvoice = () => API.post(`auth/v2/subscription/invoice/create`);
export const getInvoiceById = (id) => API.get(`auth/v2/subscription/invoice/${id}`);
export const getSubscriptionStatusById = (id) => API.get(`auth/v2/subscription/subscription-status/${id}`);

// branches 
export const getViewDetails = (page, limit) => API.get(`management/v1/company-management/view-details?accessKey=QURNSU5fQlJBTkNIRVNfVg%3D%3D&page=${page}&limit=${limit}`)
export const getBranches = (page, limit) => API.get(`management/v1/company-management/branches/v1?page=${page}&limit=${limit}`);
// export const createBranches = () => API.get()
export const createBranches = (form) => API.post(`management/v1/company-management/branches/v1`, form);
export const updateBranches = (form) => API.put(`management/v1/company-management/branches/v1`, form);
// add v1

// grade
export const getGrades = (page, limit) => API.get(`management/grade/v1?page=${page}&limit=${limit}`);
export const createGrades = (form) => API.post(`management/grade/v1`, form);
export const updateGrades = (grade, form) => API.put(`management/grade/v1/${grade}`, form);

// cost centers
export const getCostCenters = (page, limit) => API.get(`management/v1/cost-center/v1?page=${page}&limit=${limit}`);
export const createCostCenters = (form) => API.post(`management/v1/cost-center/v1`, form);
export const updateCostCenters = (id, form) => API.put(`management/v1/cost-center/v1/${id}`, form);

// group type
export const getGroupTypesAll = (page, limit) => API.get(`management/v1/group-types/all?page=${page}&limit=${limit}`);
export const getGroupTypesGroup = (id) => API.get(`management/v1/group-types/getGroupsByGroupType/${id}`);
export const createGroupTypes = (form) => API.post(`management/v1/group-types`, form);
export const updateGroupTypes = (id, groupType) => API.put(`management/v1/group-types/${id}?GroupType=${groupType}`);
export const toggleGroupTypes = (id, toggle) => API.put(`management/v1/group-types/toggleDefault/${id}?Default=${toggle}`);
export const deleteGroupTypes = (id) => API.delete(`management/v1/group-types/${id}`);

// groups
export const getGroups = (page, limit) => API.get(`management/v1/groups/v1/?page=${page}&limit=${limit}`);
export const createGroups = (form) => API.post(`management/v1/groups/v1/`, form);
export const updateGroups = (id, form) => API.put(`management/v1/groups/v1/${id}`, form);

export const getGroupEmployees = (id) => API.get(`management/v1/groups/allEmployees/v1?groupId=${id}`);
export const addGroupEmployee = (form) => API.post('management/v1/groups/addEmployees/v1', form);
export const removeGroupEmployee = (id, empId) => API.delete(`management/v1/groups/removeEmployee/v1?groupId=${id}&employeeId=${empId}`);

// employees
export const getUserRoles = () => API.get('management/search/USER%20ROLE');
export const getEmployees = (page, limit, filter) => API.get(`management/v1/employee/v1?page=${page}&limit=${limit}${filter}`);
export const getEmployeeDetail = (empId) => API.get(`management/v1/employee/v1/${empId}`);
export const getEmployeeGroups = (empId) => API.get(`management/v1/groups/v1?EmployeeId=${empId}`);
export const addEmployee = (form) => API.post(`auth/employee-register`, form);
export const updateEmployee = (id, form) => API.put(`management/v1/employee/v1/${id}`, form);

export const updateUserStatus = (id, form) => API.put(`auth/status/${id}`, form);

export const assignLicense = (form) => API.post(`auth/v2/subscription/assign-license`, form);
export const unassignLicense = (form) => API.post(`auth/v2/subscription/unassign-license/`, form);

export const employeeRegister = (form) => API.post(`auth/employee-register`, form);

export const sendActivationMail = (form) => API.post(`management/v1/employee/sendAccountActivationEmail`, form);

// ------------ roles && permission ------------------
export const getProductList = () => API.get(`auth/v2/permissions/getProducts`);
export const getCompanyRoles = (id) => API.get(`auth/v2/permissions/company-roles/all?ProductId=${id}`);
export const getAllUserAccess = (empId) => API.get(`auth/v2/permissions/user-access/getAll?EmployeeId=${empId}`);
export const createUserAccess = (empId, form) => API.post(`auth/v2/permissions/user-access/create?EmployeeId=${empId}`, form);
export const deleteUserAccess = (id, empId) => API.delete(`auth/v2/permissions/user-access/remove/${id}?EmployeeId=${empId}`);

// ------------------- miscellaneous ------------------------
export const getGroupTypes = (page, limit) => API.get(`management/v1/group-types/all?page=${page}&limit=${limit}`);
export const getCityList = (query) => API.get(`management/v1/search/city-list?CityLocation=${query}`);
export const searchApprovers = (query) => API.get(`management/employee/search/${query}`);

export const searchEmployee = (name) => API.get(`management/v1/employee?Name=${name}&accessKey=QURNSU5fRU1QTE9ZRUVfVg%3D%3D`);
