import * as actionTypes from "../actionTypes.js";

const initialState = {
  groupTypes: null,
  cityList: null, 
  approvers: null,
  employees: null
};

const miscellaneousReducer = (state = initialState, action) => {
  switch (action.type) {

    case actionTypes.GET_GROUP_TYPES:
      return { ...state, groupTypes: action.payload };
    case actionTypes.GET_CITY_LIST:
      return { ...state, cityList: action.payload };
    case actionTypes.SEARCH_APPROVER:
      return { ...state, approvers: action.payload };
    case actionTypes.SEARCH_EMPLOYEE:
      return { ...state, employees: action.payload };

    default:
      return state;
  }
};

export default miscellaneousReducer;
