import * as actionTypes from "../actionTypes.js";

const initialState = {
  costCenters: null
};

const costCentersReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_COST_CENTERS:
      return { ...state, costCenters: action.payload };

    default:
      return state;
  }
};

export default costCentersReducer;
